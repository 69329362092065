<template>
  <q-tr
      :props="data"
      class="clickable"
      @dblclick="onRowDblClick(data.row)"
  >
    <td class="q-td text-left">
      <q-checkbox
          size="xl"
          :model-value="!!items.find(({id}) => id === data.row.id)"
          color="amber-6"
          @click="handleCheck(data.row)"
      />
    </td>

    <q-td
        key="number"
        :props="data"
        auto-width
    >
      {{ data.rowIndex + 1 }}
    </q-td>

    <q-td
        key="id"
        :props="data"
        class="text-subtitle1"
        auto-width
        style="max-width: 250px; white-space: pre-wrap !important;"
    >
      <strong>{{ data.row.id }}</strong>

      <div class="text-caption">
        {{ data.row.extId }}
      </div>

    </q-td>

    <q-td
        key="state"
        :props="data"
        auto-width
    >
      <q-badge
          :color="stateColors[data.row.state]"
          text-color="dark"
          class="q-pa-sm"
      >
        {{ $t(data.row.state) }}
      </q-badge>
    </q-td>


    <q-td
        key="storage"
        :props="data"
        style="max-width: 250px; white-space: pre-wrap !important; text-wrap: normal !important;"
    >
      <strong v-if="data.row._embedded && data.row._embedded.warehouse">
        {{ data.row._embedded.warehouse.name }}
      </strong>
    </q-td>

    <q-td
        key="created"
        :props="data"
        style="width: 100px; white-space: pre-wrap !important;"
    >
      {{
        $moment(data.row.created.date + data.row.created.timezone).format(appOptions?.formats?.fullDate)
      }}
    </q-td>

    <q-td
        key="comment"
        :props="data"
        style="max-width: 250px; white-space: pre-wrap !important;text-wrap: normal !important;"
    >
      <div class="text-caption " v-if="data.row.errors">
        <q-chip square icon="error_outline" color="red" text-color="white">{{ $t(`Attention needed`) }}</q-chip>

        <q-tooltip class="q-px-sm text-left">
          <ul class="q-ma-none q-pl-md" style="max-width: 350px">
            <li class="text-caption text-weight-light" v-for="error in data.row.errors">
              {{ error }}
            </li>
          </ul>
        </q-tooltip>
      </div>

      {{ data.row.comment }}
    </q-td>

    <q-td
        key="action"
        :props="data"
        auto-width
    >
      <action-lazy-dropdown :option="options"/>
    </q-td>
  </q-tr>
</template>

<script>
// Vuex
import {mapActions, mapGetters, mapMutations} from 'vuex'

// Components
import ActionLazyDropdown from '../action-header/ActionLazyDropdown.vue'

export default {
  name: 'AcceptanceRow',
  emits: ['check'],
  components: {
    ActionLazyDropdown
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    },
    data: {
      type: Object,
      required: true,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      stateColors: {
        new: 'info',
        confirmed: 'yellow',
        checking: 'warning',
        complete: 'success',
        deleted: 'danger',
        preset: 'warning',
        archived: 'dark text-white',
        closed: 'dark text-white'
      },
      entityClass: 'Orderadmin\\Storage\\Entity\\Movement\\Acceptance'
    }
  },
  computed: {
    ...mapGetters([
      'appOptions'
    ]),
    options() {
      return {
        color: 'light-blue-9',
        options: [],
        onScroll: (search, page) => {
          const query = {
            search,
            page,
            filter: [
              {type: 'eq', field: 'state', value: 'active'},
              {type: 'eq', field: 'entityClass', value: this.entityClass}
            ]
          }

          return this.$service.documentTemplate.getAll(query)
        },
        onClick: (item) => {
          const fullUrl = this.data.row._links.self.href;
          const urlObj = new URL(fullUrl);
          let url = urlObj.pathname; // This gives you the path after the domain

          if (url.startsWith('/api/')) {
            url = url.replace('/api/', '/');
          }

          if (item.id === 'default') {
            const data = {url: url, id: this.data.row.id}
            return this.downloadAcceptancePDF(data)
                .catch(error => {
                  this.addErrorNotification(error)
                })
          }

          return this.$service.acceptance.getTemplate(url, item.id)
              .then(data => {
                this.$service.printer.print(data, undefined, true)
                this.stopLoading()
              })
              .catch(error => {
                this.stopLoading()
                this.addErrorNotification(error)
              })
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'stopLoading',
      'addErrorNotification'
    ]),
    ...mapActions([
      'downloadAcceptancePDF'
    ]),
    onRowDblClick(item) {
      this.$emit('on-dblclick', item)
    },
    chooseTemplate(acceptance) {
      this.$refs.documentModal.open(acceptance)
    },
    handleCheck() {
      this.$emit('check', this.data.row)
    },
  }
}
</script>
